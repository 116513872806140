import { i18n } from "@lingui/core";
import { useFormContext, useWatch } from "react-hook-form";
import { RHFLabeledSelect } from "~/components/rhf";
import type { SignInFormData } from "~/types/auth";
import { trpc } from "~/utils/trpc";
export const UserField: React.FC<{
  fieldEnabled: boolean;
}> = ({
  fieldEnabled
}) => {
  const methods = useFormContext<SignInFormData>();
  const roleFilter = {
    type: useWatch<SignInFormData>({
      name: "roleType"
    }),
    departmentId: useWatch<SignInFormData>({
      name: "roleDepartmentId"
    }),
    subDepartmentId: useWatch<SignInFormData>({
      name: "roleSubDepartmentId"
    }),
    serviceId: useWatch<SignInFormData>({
      name: "roleServiceId"
    })
  };
  const query = trpc.users.list.useQuery({
    filters: {
      role: roleFilter
    }
  }, {
    enabled: fieldEnabled
  });
  if (!fieldEnabled) {
    return null;
  }
  if (query.isLoading) {
    return "Loading...";
  }
  if (query.isError) {
    return "Une erreur est survenue";
  }
  const options = query.data.users.map(({
    email,
    full_name
  }) => ({
    id: email,
    label: full_name
  }));
  return <RHFLabeledSelect shouldUnregister={true} rules={{
    onChange: e => methods.setValue("email", e.target.value)
  }} label={i18n._({
    id: "common.user",
    message: "Utilisateur"
  })} name="userId" options={options} data-sentry-element="RHFLabeledSelect" data-sentry-component="UserField" data-sentry-source-file="userField.tsx" />;
};