import type { FileOriginType } from "~/types/database";

const FILE_ORIGIN = {
  // FROM APPLICANT TO WBI
  FROM_APPLICANT_APPLICATION_FORM: "from_applicant_application_form",
  FROM_APPLICANT_APPLICATION_FORM_ANSWER: "from_applicant_application_form_answer",
  FROM_APPLICANT_REVIEW_FORM: "from_applicant_review_form",
  FROM_APPLICANT_REVIEW_FORM_ANSWER: "from_applicant_review_form_answer",
  FROM_APPLICANT_REVIEW_FORM_ANSWER_BY_SUBVENTION: "from_applicant_review_form_answer_by_subvention",
  FROM_APPLICANT_CLAIM_STATEMENT: "from_applicant_claim_statement",
  FROM_APPLICANT_CONTROL_BY_LIQUIDATION: "from_applicant_control_by_liquidation",
  FROM_APPLICANT_GENERAL_CONTROL: "from_applicant_general_control",
  FROM_APPLICANT_APPLICATION_DATA: "from_applicant_application_data",
  FROM_APPLICANT_BENEFICIARY_RIB: "from_applicant_beneficiary_rib",
  FROM_APPLICANT_OTHER: "from_applicant_other",

  // FROM WBI TO APPLICANT
  FROM_WBI_GENERATED: "from_wbi_generated",
  FROM_WBI_REFUSAL_FILES_GENERATED: "from_wbi_refusal_files_generated",
  FROM_WBI_SUBVENTION_FILES_GENERATED: "from_wbi_subvention_files_generated",
  FROM_WBI_OTHER: "from_wbi_other",

  // INTERNAL TO WBI
  INTERNAL_DECISION: "internal_decision",
  INTERNAL_OTHER: "internal_other",

  // TEMPORARY CREATED FOR SIGNING
  TEOMPORARY_CREATED_FOR_SIGNING: "temporary_created_for_signing",

  // PDF
  PDF_LOGO: "pdf_logo",
  PDF_PROMOTIONAL_BANNER: "pdf_promotional_banner",

  NEW_ORGANISATION: "new_organisation",

  //  For samples
  TEST: "test",
} as const;

const FILES_FROM_APPLICANT: FileOriginType[] = [
  FILE_ORIGIN.FROM_APPLICANT_APPLICATION_FORM,
  FILE_ORIGIN.FROM_APPLICANT_APPLICATION_FORM_ANSWER,
  FILE_ORIGIN.FROM_APPLICANT_REVIEW_FORM,
  FILE_ORIGIN.FROM_APPLICANT_REVIEW_FORM_ANSWER,
  FILE_ORIGIN.FROM_APPLICANT_APPLICATION_DATA,
  FILE_ORIGIN.FROM_APPLICANT_CLAIM_STATEMENT,
  FILE_ORIGIN.FROM_APPLICANT_CONTROL_BY_LIQUIDATION,
  FILE_ORIGIN.FROM_APPLICANT_GENERAL_CONTROL,
  FILE_ORIGIN.FROM_APPLICANT_BENEFICIARY_RIB,
  FILE_ORIGIN.FROM_APPLICANT_OTHER,
];
const FILES_FROM_WBI: FileOriginType[] = [
  FILE_ORIGIN.FROM_WBI_GENERATED,
  FILE_ORIGIN.FROM_WBI_SUBVENTION_FILES_GENERATED,
  FILE_ORIGIN.FROM_WBI_REFUSAL_FILES_GENERATED,
  FILE_ORIGIN.FROM_WBI_OTHER,
];
const INTERNAL_FILES: FileOriginType[] = [FILE_ORIGIN.INTERNAL_DECISION, FILE_ORIGIN.INTERNAL_OTHER];

const FILE_STATUS = {
  DRAFT: "draft",
  SAVED: "saved",
  PUBLISHED: "published",
} as const;

const VIDEO_FILES_EXTENSIONS = [".mov", ".mp4", ".wmv", ".avi"];
const IMAGE_FILES_EXTENSIONS = [".jpg", ".jpeg", ".png"];
const MICROSOFT_OFFICE_FILES_EXTENSIONS = [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx"];
const OTHER_FILES_EXTENSIONS = [".pdf", ".txt"];
const DEFAULT_ACCEPTED_EXTENSIONS = [...VIDEO_FILES_EXTENSIONS, ...IMAGE_FILES_EXTENSIONS, ...MICROSOFT_OFFICE_FILES_EXTENSIONS, ...OTHER_FILES_EXTENSIONS];

const DOCUMENTS_QUESTION_CONFIGURABLE_EXTENSIONS = [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".gif", ".png", ".pdf", ".odt", ".ods"];

const KILO_BYTE = 1024;
const MEGA_BYTE = 1024 * KILO_BYTE;
const DEFAULT_MAX_SIZE = 25 * MEGA_BYTE;

export {
  FILE_ORIGIN,
  FILES_FROM_APPLICANT,
  FILES_FROM_WBI,
  INTERNAL_FILES,
  FILE_STATUS,
  DEFAULT_MAX_SIZE,
  DEFAULT_ACCEPTED_EXTENSIONS,
  IMAGE_FILES_EXTENSIONS,
  DOCUMENTS_QUESTION_CONFIGURABLE_EXTENSIONS,
};
