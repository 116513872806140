import sortBy from "lodash/sortBy";
import React from "react";
import { RHFLabeledSelect } from "~/components/rhf";
import { STATIC_ROLES } from "~/constants/users";
import { i18n } from "~/i18nSetup";
export const RoleTypeField: React.FC = () => {
  const options = Object.entries(STATIC_ROLES).map(([key, label]) => ({
    id: key,
    label: i18n._(label)
  }));
  const sortedOptions = sortBy(options, "label");
  return <RHFLabeledSelect label={i18n._({
    id: "common.role",
    message: "Rôle"
  })} name="roleType" options={sortedOptions} data-sentry-element="RHFLabeledSelect" data-sentry-component="RoleTypeField" data-sentry-source-file="roleTypeField.tsx" />;
};