import { createHeadlessEditor } from "@lexical/headless";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import type { SerializedHeadingNode } from "@lexical/rich-text";
import clsx from "clsx";
import type { ElementFormatType, SerializedEditorState, SerializedLexicalNode, SerializedParagraphNode, Spread } from "lexical";
import { $getRoot, ParagraphNode } from "lexical";
import React, { forwardRef, useEffect, useState } from "react";
import { EMPTY_RICHTEXT, TRANSFORMERS } from "./constant";
import AutoLinkPlugin from "./plugins/auto-link-plugin";
import { ResetEditorPlugin } from "./plugins/clear-editor-plugin";
import { FloatingLinkEditorPlugin } from "./plugins/floating-link-editor-plugin";
import { LinkPlugin } from "./plugins/link-plugin";
import { ListenValueChangePlugin } from "./plugins/listen-initialValue-plugin";
import { MaxLengthPlugin } from "./plugins/max-length-plugin";
import { TabFocusPlugin } from "./plugins/tab-focus-plugin";
import { ToolbarPlugin } from "./plugins/toolbar-plugin";
import { CAN_USE_DOM } from "./shared/canUseDOM";
import { validateUrl } from "./utils/url";
export const getSimpleRichText = (value: string) => {
  return JSON.stringify({
    root: {
      children: [{
        children: [{
          detail: 0,
          format: 0,
          mode: "normal",
          style: "",
          text: value,
          type: "text",
          version: 1
        }],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1
      }],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1
    }
  });
};
export type LexicalSerializedValue = SerializedEditorState;
export type LexicalSerializedHeadingNode = SerializedHeadingNode;
export type RichTextSerializedNode = SerializedHeadingNode | SerializedParagraphNode;
export type RichTextSerializedValue = {
  root: Spread<{
    children: Array<RichTextSerializedNode>;
    direction: "ltr" | "rtl" | null;
    format: ElementFormatType;
    indent: number;
  }, SerializedLexicalNode>;
};

// This function is exported to the app. Should be moved away from here.
export function getLexicalTextContentLength(value: string): number {
  const editor = createHeadlessEditor({
    nodes: [ParagraphNode, ListNode, ListItemNode],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {}
  });
  editor.setEditorState(editor.parseEditorState(value));
  const length = editor.getEditorState().read(() => {
    const rootNode = $getRoot();
    const textContent = rootNode.getTextContent();
    return textContent.length;
  });
  return length;
}
const LexicalPlaceholder = forwardRef(function Placeholder({
  value = undefined,
  toolbox = true,
  className,
  id,
  ...props
}: {
  value?: string;
  toolbox: boolean;
  id: string;
} & React.HTMLAttributes<HTMLDivElement>, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
  const [editor] = useLexicalComposerContext();
  return <div ref={forwardedRef} className={clsx("m-0 -mt-[248px] leading-[55px] inline-block cursor-text truncate p-2", toolbox ? "top-10" : "top-0", className)} onClick={() => editor.focus()} tabIndex={-1} role="button" aria-hidden="true" {...props}>
      <BasicRichTextEditor isPlaceholder id={id} readOnly={true} value={value} />
    </div>;
});
export type InitialConfig = React.ComponentProps<typeof LexicalComposer>["initialConfig"];
export const BasicRichTextEditor = forwardRef(function BasicRichTextEditor({
  id,
  value,
  onChange,
  children,
  className,
  placeholder,
  isPlaceholder,
  readOnly = false,
  disabled = false,
  editorClassName = "",
  maxChar,
  ...props
}: {
  id?: string;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  isPlaceholder?: boolean;
  className?: string;
  value?: string | undefined;
  onChange?: (value?: string) => void;
  editorClassName?: string;
  maxChar?: number;
  children?: React.ReactNode;
} & React.ComponentProps<"div">, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
  const isClean = React.useRef(true);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;
      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);
    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);
  const initialConfig: InitialConfig = React.useMemo(() => {
    return {
      namespace: "Comment",
      theme: {
        text: {
          bold: clsx("font-bold"),
          italic: clsx("italic"),
          underline: clsx("underline"),
          strikethrough: clsx("line-through")
        },
        list: {
          listitem: "PlaygroundEditorTheme__listItem",
          listitemChecked: "PlaygroundEditorTheme__listItemChecked",
          listitemUnchecked: "PlaygroundEditorTheme__listItemUnchecked",
          nested: {
            listitem: clsx("after:hidden before:hidden list-none")
          },
          ol: clsx("list-decimal pl-6"),
          ul: clsx(readOnly ? " pl-0 list-none custom-bullet" : "pl-6"),
          ulDepth: [clsx("list-disc"), clsx("list-[circle]"), clsx("list-[square]")],
          olDepth: [clsx("list-decimal"), clsx("list-[upper-alpha]"), clsx("list-[lower-alpha]"), clsx("list-[upper-roman]"), clsx("list-[lower-roman]")]
        },
        link: "text-cherry-red hover:underline hover:cursor-pointer"
      },
      onError: error => console.error(error),
      // Any custom nodes go here
      id,
      nodes: [ParagraphNode, ListNode, ListItemNode, LinkNode, AutoLinkNode],
      editorState: value,
      editable: !disabled && !readOnly
    };
  }, [readOnly, disabled]);
  return <LexicalComposer initialConfig={initialConfig}>
      <div ref={forwardedRef} className={clsx("relative flex w-full flex-col", disabled && "bg-neutral-100", className)} {...props}>
        {!readOnly && <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} disabled={disabled} />}
        <>
          <RichTextPlugin contentEditable={<div className={clsx("editor-scroller relative flex z-0 overflow-auto outline-0 min-h-[14.5rem]", !readOnly && "min-h-[12.5rem]" //Min height is only needed in edition mode.
        )}>
                <div className="flex-auto resize-y relative" ref={onRef}>
                  <ContentEditable className={clsx("relative 0 w-full resize-none overflow-auto focus:outline-none", isPlaceholder ? "italic text-neutral-600" : "text-neutral-900", editorClassName)} />
                </div>
              </div>} placeholder={placeholder ? <LexicalPlaceholder id={`${id}.placeholder`} value={placeholder} toolbox /> : null} ErrorBoundary={LexicalErrorBoundary} />
          <TabIndentationPlugin />
          <ListPlugin />
          <LinkPlugin validateUrl={validateUrl} />
          <AutoLinkPlugin />
          {readOnly ? <ListenValueChangePlugin value={value} /> : <>
              {floatingAnchorElem && !isSmallWidthViewport && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} isLinkEditMode={isLinkEditMode} setIsLinkEditMode={setIsLinkEditMode} />}
              <ResetEditorPlugin isDirty={!isClean.current} defaultValue={initialConfig.editorState} currentValue={value} />
              <OnChangePlugin onChange={editorState => {
            if (isClean) isClean.current = false;
            const json = editorState.toJSON();
            const string = JSON.stringify(json);
            if (onChange) onChange(string === EMPTY_RICHTEXT ? undefined : string);
          }} ignoreSelectionChange />
              <TabFocusPlugin />
              <HistoryPlugin />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

              {maxChar ? <MaxLengthPlugin maxLength={maxChar} /> : null}
            </>}
          {children}
        </>
      </div>
    </LexicalComposer>;
});