import clsx from "clsx";
import React from "react";
import type { FieldError } from "react-hook-form";
import { Typography } from "~/components/typography";
export const FormLabel: React.FC<React.ComponentPropsWithoutRef<"label"> & {
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}> = ({
  children,
  required,
  disabled,
  readonly,
  ...props
}) => <label className={clsx("text-body mb-2 w-fit text-left font-semibold", disabled ? "text-neutral-600" : "text-neutral-800", required && !readonly && "after:text-cherry-red after:content-['*'] after:pl-1 flex gap-x-1")} {...props} data-sentry-component="FormLabel" data-sentry-source-file="field-container.tsx">
    {children}
  </label>;
export const FormError: React.FC<{
  error: FieldError | string;
}> = ({
  error
}) => {
  if (typeof error === "string") {
    return <Typography.error className="mt-2 block text-left font-semibold leading-4">{error}</Typography.error>;
  }
  return <Typography.error className="mt-2 block text-left font-semibold leading-4" data-sentry-element="unknown" data-sentry-component="FormError" data-sentry-source-file="field-container.tsx">{error.message}</Typography.error>;
};
export const InputContainer: React.FC<React.PropsWithChildren<{
  name?: string;
  className?: string;
  required?: boolean;
  error?: FieldError | string;
  label?: React.ReactNode;
  description?: string;
  disabled?: boolean;
  readOnly?: boolean;
}>> = ({
  name,
  label,
  description,
  required,
  error,
  className,
  children,
  disabled = false,
  readOnly
}) => {
  return <div className={clsx("flex w-full flex-col", className)} data-sentry-component="InputContainer" data-sentry-source-file="field-container.tsx">
      {label && <FormLabel readonly={readOnly} required={required} disabled={disabled} htmlFor={name}>
          <span className="line-clamp-1 text-ellipsis">{label}</span>
        </FormLabel>}
      {description && <div className={clsx("mb-3 text-sm text-neutral-700", disabled && "text-neutral-600")}>{description}</div>}
      {children}
      {error && <FormError error={error} />}
    </div>;
};