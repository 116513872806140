import clsx from "clsx";
import { ChevronsUpDown, SearchIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { Button } from "../Button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ComboBox/Command";
import { Popover, PopoverContent, PopoverTrigger } from "../ComboBox/Popover";
import { MultiOption } from "../ComboBox/types";
import { useComboboxSelection } from "../ComboBox/useComboboxSelection";
import { getOptionsItems } from "../ComboBox/utils";
import Typography from "../Typography";
import { Badge } from "../badge";
import { Checkbox } from "../inputs/checkBox";
export type Props<Option extends MultiOption> = {
  readonly searchKeys?: ReadonlyArray<"label" | "value">;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly options: Option[];
  readonly isError?: boolean;
  readonly value: Option[];
  readonly isMultipleChoice?: boolean;
  readonly className?: string;
  readonly chevronClassName?: string;
  readonly onChange: (value: Option[]) => void;
};
export function SearchableSelect<Option extends MultiOption>({
  options,
  placeholder = "Sélectionner...",
  searchKeys = ["label"],
  isError,
  value = [],
  onChange,
  disabled,
  className,
  chevronClassName,
  isMultipleChoice = false,
  ...props
}: Props<Option>) {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filteredOptionItems = useMemo(() => getOptionsItems({
    optionItems: options,
    inputValue,
    keys: searchKeys
  }), [options, inputValue, searchKeys]);
  const {
    selectedItems,
    setSelectedItems,
    addSelectedItem,
    getSelectedItemProps,
    removeSelectedItem,
    getDropdownProps
  } = useComboboxSelection<Option>({
    selectedItems: value,
    onValueChange: onChange,
    multiple: isMultipleChoice
  });
  return <div data-sentry-component="SearchableSelect" data-sentry-source-file="SearchableSelect.tsx">
      <Popover open={isOpen} onOpenChange={setIsOpen} data-sentry-element="Popover" data-sentry-source-file="SearchableSelect.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="SearchableSelect.tsx">
          <Button type="button" variant="ghost" flavor="neutral" role="combobox" aria-expanded={isOpen} className={clsx("w-[200px] justify-between", className, {
          " border border-error": isError
        })} disabled={disabled} data-sentry-element="Button" data-sentry-source-file="SearchableSelect.tsx">
            <div className="flex items-center gap-1">
              {selectedItems.length > 0 ? <>
                  <Badge onClose={disabled || !isMultipleChoice ? undefined : event => {
                event.stopPropagation();
                removeSelectedItem(selectedItems[0]);
              }} {...getSelectedItemProps({
                selectedItem: selectedItems[0],
                index: 0
              })} className={clsx(isMultipleChoice ? "rounded-full bg-sky-blue text-white" : "text-neutral-800")}>
                    {selectedItems[0].label}
                  </Badge>
                  {selectedItems.length > 1 && <Badge className="bg-sky-blue text-white">+ {selectedItems.length - 1}</Badge>}
                </> : <Typography.note>{placeholder}</Typography.note>}
            </div>
            <ChevronsUpDown className={`ml-2 h-4 w-4 shrink-0 opacity-50 ${chevronClassName}`} data-sentry-element="ChevronsUpDown" data-sentry-source-file="SearchableSelect.tsx" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[200px] p-2" data-sentry-element="PopoverContent" data-sentry-source-file="SearchableSelect.tsx">
          <Command {...props} data-sentry-element="Command" data-sentry-source-file="SearchableSelect.tsx">
            <div className="w-full flex items-center border-b">
              <SearchIcon className="h-5 w-5 text-neutral-600" data-sentry-element="SearchIcon" data-sentry-source-file="SearchableSelect.tsx" />
              <CommandInput placeholder="Rechercher..." value={inputValue} onValueChange={setInputValue} className={clsx({
              "border border-error": isError
            })} {...getDropdownProps()} data-sentry-element="CommandInput" data-sentry-source-file="SearchableSelect.tsx" />
            </div>
            <CommandList data-sentry-element="CommandList" data-sentry-source-file="SearchableSelect.tsx">
              {filteredOptionItems.length === 0 ? <CommandEmpty>Aucune option trouvée.</CommandEmpty> : <CommandGroup>
                  {filteredOptionItems.map(option => {
                const isSelected = selectedItems.some(selectedItem => selectedItem.id === option.id);
                return <CommandItem key={option.id} onSelect={() => {
                  const element = selectedItems.find(selectedItem => selectedItem.id === option.id);
                  if (element) {
                    removeSelectedItem(element);
                  } else {
                    if (isMultipleChoice) {
                      addSelectedItem(option);
                    } else {
                      setSelectedItems([option]);
                      setIsOpen(false);
                    }
                  }
                }}>
                        <Checkbox readOnly rounded={!isMultipleChoice} className="mr-2 peer border-aubergine" checked={isSelected} />
                        {option.label}
                      </CommandItem>;
              })}
                </CommandGroup>}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {isError && <p className="text-error text-sm mt-1">Une erreur est survenue.</p>}
    </div>;
}