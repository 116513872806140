import { UseMultipleSelectionReturnValue, useMultipleSelection } from "downshift";
import { uniqBy } from "lodash";
import { MultiOption } from "../ComboBox/types";
const {
  FunctionAddSelectedItem,
  FunctionRemoveSelectedItem
} = useMultipleSelection.stateChangeTypes;
export function useComboboxSelection<T extends MultiOption>({
  selectedItems,
  onValueChange,
  uniqueById = true,
  multiple = true
}: {
  selectedItems: T[];
  onValueChange: (data: T[]) => void;
  uniqueById?: boolean;
  multiple?: boolean;
}): UseMultipleSelectionReturnValue<T> {
  const selection = useMultipleSelection<T>({
    selectedItems,
    onSelectedItemsChange: ({
      selectedItems
    }) => {
      onValueChange(selectedItems || []);
    },
    stateReducer: (_state, actionAndChanges) => {
      const {
        changes,
        type
      } = actionAndChanges;
      switch (type) {
        case FunctionAddSelectedItem:
          {
            if (!multiple) {
              return {
                ...changes,
                selectedItems: changes.selectedItems?.slice(-1)
              };
            }
            if (uniqueById) {
              return {
                ...changes,
                selectedItems: uniqBy<T>(changes.selectedItems, "id")
              };
            }
            return {
              ...changes,
              selectedItems: changes.selectedItems
            };
          }
        case FunctionRemoveSelectedItem:
          {
            return {
              ...changes,
              selectedItems: changes.selectedItems
            };
          }
      }
      return changes;
    }
  });
  return selection;
}