import { Dialog, DialogContent, type DialogProps } from "@radix-ui/react-dialog";
import clsx from "clsx";
import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
const Command = React.forwardRef<React.ElementRef<typeof CommandPrimitive>, React.ComponentPropsWithoutRef<typeof CommandPrimitive>>(({
  className,
  ...props
}, ref) => <CommandPrimitive ref={ref} className={clsx("flex h-full w-full flex-col overflow-hidden rounded-md", className)} {...props} />);
Command.displayName = CommandPrimitive.displayName;
type CommandDialogProps = DialogProps;
const CommandDialog = ({
  children,
  ...props
}: CommandDialogProps) => {
  return <Dialog {...props} data-sentry-element="Dialog" data-sentry-component="CommandDialog" data-sentry-source-file="Command.tsx">
      <DialogContent className="overflow-hidden p-0 shadow-lg" data-sentry-element="DialogContent" data-sentry-source-file="Command.tsx">
        <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5" data-sentry-element="Command" data-sentry-source-file="Command.tsx">
          {children}
        </Command>
      </DialogContent>
    </Dialog>;
};
const CommandInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>>(({
  className,
  ...props
}, ref) => <div className="flex items-center w-full" cmdk-input-wrapper="">
      <CommandPrimitive.Input ref={ref} className={clsx("flex h-11 w-full bg-transparent border-none py-3 text-sm outline-none placeholder:text-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none focus:ring-0 focus:ring-offset-0", className)} {...props} />
    </div>);
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandList = React.forwardRef<React.ElementRef<typeof CommandPrimitive.List>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.List ref={ref} className={clsx("max-h-[300px] overflow-y-auto overflow-x-hidden", className)} {...props} />);
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Empty>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>>((props, ref) => <CommandPrimitive.Empty ref={ref} className="py-6 text-center text-sm" {...props} />);
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Group>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.Group ref={ref} className={clsx("overflow-hidden p-1 text-neutral-700 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground", className)} {...props} />);
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.Separator ref={ref} className={clsx("-mx-1 h-px", className)} {...props} />);
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandItem = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Item>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.Item ref={ref} className={clsx("relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled=true]:pointer-events-none data-[selected='true']:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50", className)} {...props} />);
CommandItem.displayName = CommandPrimitive.Item.displayName;
const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span className={clsx("ml-auto text-xs tracking-widest text-muted-foreground", className)} {...props} data-sentry-component="CommandShortcut" data-sentry-source-file="Command.tsx" />;
};
CommandShortcut.displayName = "CommandShortcut";
export { Command, CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem, CommandShortcut, CommandSeparator };