import { Trans } from "@lingui/react";
import Image from "next/image";
import logoCSAM from "~/casm.svg";
import { Button } from "~/components/button";
import { Typography } from "~/components/typography";
import { useRouter } from "~/hooks/router";
import logoITSME from "~/itsme.svg";
export const AuthFormProd: React.FC<{
  kcIdpHint?: string;
  postLoginPath: string;
}> = ({
  kcIdpHint,
  postLoginPath
}) => {
  const router = useRouter();
  return <div className="p-8 flex items-center justify-center" data-sentry-component="AuthFormProd" data-sentry-source-file="authFormProd.tsx">
      <div className="flex flex-col items-center px-[72px]">
        <Typography.h1 className="pb-20 text-[38px] leading-[44px]" data-sentry-element="unknown" data-sentry-source-file="authFormProd.tsx">
          <Trans id="auth.log_in_to_your_account" message="Bienvenue sur MonWBI" data-sentry-element="Trans" data-sentry-source-file="authFormProd.tsx" />
        </Typography.h1>
        <div className="flex items-center gap-x-1 pb-4">
          <Typography.h3 fontWeight="600" data-sentry-element="unknown" data-sentry-source-file="authFormProd.tsx">
            <Trans id="auth.connection_with" message="Connectez-vous rapidement avec" data-sentry-element="Trans" data-sentry-source-file="authFormProd.tsx" />
          </Typography.h3>
          <Image src={logoCSAM} alt="CASM" className="relative object-contain" data-sentry-element="Image" data-sentry-source-file="authFormProd.tsx" />
          <Typography.h3 fontWeight="600" data-sentry-element="unknown" data-sentry-source-file="authFormProd.tsx">
            <Trans id="auth.connection_alternative" message="ou" data-sentry-element="Trans" data-sentry-source-file="authFormProd.tsx" />
          </Typography.h3>
          <Image src={logoITSME} alt="itsme" className="relative object-contain" data-sentry-element="Image" data-sentry-source-file="authFormProd.tsx" />
        </div>
        <Typography.body className="pb-10 text-center" data-sentry-element="unknown" data-sentry-source-file="authFormProd.tsx">
          <Trans id="auth.explanation" message="Dans le cas où vous vous connectez pour la première fois, des informations supplémentaires vous seront demandé après vous être identifiez via CSMA ou itsme." data-sentry-element="Trans" data-sentry-source-file="authFormProd.tsx" />
        </Typography.body>
        <Button variant="plain" onClick={() => {
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query,
            kc_idp_hint: kcIdpHint
          }
        });
      }} data-sentry-element="Button" data-sentry-source-file="authFormProd.tsx">
          <Trans id="auth.log_in" message="Me connecter via CSAM ou itsme" data-sentry-element="Trans" data-sentry-source-file="authFormProd.tsx" />
        </Button>

        <button className=" w-8 h-8 absolute bg-transparent opacity-0  bottom-5 right-5" type="button" onClick={() => {
        const redirectUrl = `${window.location.origin}/api/auth/login?post_login_path=${encodeURIComponent(postLoginPath)}`;
        router.push(redirectUrl);
      }} />
      </div>
    </div>;
};