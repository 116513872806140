import { ArrowPathIcon, ArrowRightIcon, ArrowUturnLeftIcon, ArrowUturnRightIcon, Bars3BottomLeftIcon, Bars3BottomRightIcon, Bars3Icon, ListBulletIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import clsx from "clsx";
import { $getSelection, $isRangeSelection, CAN_REDO_COMMAND, CAN_UNDO_COMMAND, COMMAND_PRIORITY_NORMAL, FORMAT_ELEMENT_COMMAND, FORMAT_TEXT_COMMAND, INDENT_CONTENT_COMMAND, KEY_MODIFIER_COMMAND, REDO_COMMAND, SELECTION_CHANGE_COMMAND, UNDO_COMMAND } from "lexical";
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { RESET_EDITOR_COMMAND } from "../commands";
import { getSelectedNode } from "../utils/getSelectedNode";
import { sanitizeUrl } from "../utils/url";
const LowPriority = 1;
export function ToolbarPlugin({
  setIsLinkEditMode,
  disabled = false
}: {
  setIsLinkEditMode: Dispatch<boolean>;
  disabled?: boolean;
}) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  // const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);
  useEffect(() => {
    return mergeRegister(editor.registerUpdateListener(({
      editorState
    }) => {
      editorState.read(() => {
        updateToolbar();
      });
    }), editor.registerCommand(SELECTION_CHANGE_COMMAND, _payload => {
      updateToolbar();
      return false;
    }, LowPriority), editor.registerCommand(CAN_UNDO_COMMAND, payload => {
      setCanUndo(payload);
      return false;
    }, LowPriority), editor.registerCommand(CAN_REDO_COMMAND, payload => {
      setCanRedo(payload);
      return false;
    }, LowPriority));
  }, [editor, updateToolbar]);
  useEffect(() => {
    return editor.registerCommand(KEY_MODIFIER_COMMAND, payload => {
      const event: KeyboardEvent = payload;
      const {
        code,
        ctrlKey,
        metaKey
      } = event;
      if (code === "KeyK" && (ctrlKey || metaKey)) {
        event.preventDefault();
        if (!isLink) {
          setIsLinkEditMode(true);
        } else {
          setIsLinkEditMode(false);
        }
        return editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
      }
      return false;
    }, COMMAND_PRIORITY_NORMAL);
  }, [editor, isLink, setIsLinkEditMode]);
  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);
  const formatBulletList = useCallback(() => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [editor, blockType]);
  const formatNumberedList = useCallback(() => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [editor, blockType]);
  return <div className={clsx("flex w-full items-center gap-1 bg-transparent border-aubergine/20 border-b px-2 py-1")} ref={toolbarRef} data-sentry-component="ToolbarPlugin" data-sentry-source-file="toolbar-plugin.tsx">
      <button type="button" disabled={disabled || !canUndo} onClick={() => {
      editor.dispatchCommand(UNDO_COMMAND, undefined);
    }} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" aria-label="Undo">
        <ArrowUturnLeftIcon className="w-4 h-4" data-sentry-element="ArrowUturnLeftIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <button type="button" disabled={disabled || !canRedo} onClick={() => {
      editor.dispatchCommand(REDO_COMMAND, undefined);
    }} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" aria-label="Redo">
        <ArrowUturnRightIcon className="w-4 h-4" data-sentry-element="ArrowUturnRightIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <button type="button" disabled={disabled} onClick={() => {
      editor.dispatchCommand(RESET_EDITOR_COMMAND, undefined);
    }} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" aria-label="Redo">
        <ArrowPathIcon className="w-4 h-4" data-sentry-element="ArrowPathIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <Divider data-sentry-element="Divider" data-sentry-source-file="toolbar-plugin.tsx" />
      <button type="button" disabled={disabled} onClick={() => {
      editor.focus();
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
    }} className="flex h-8 w-8 items-center justify-center font-bold rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" aria-label="Format Bold" data-active={isBold}>
        B
      </button>
      <button type="button" disabled={disabled} onClick={() => {
      editor.focus();
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
    }} className="flex h-8 w-8 items-center justify-center rounded-md italic text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" aria-label="Format Italics" data-active={isItalic}>
        I
      </button>
      {/* <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        className="data-[active='true']:bg-grey-blue-20 data-[active='true']:text-neutral-800 flex text-neutral-800 h-8 w-8 items-center justify-center rounded-md hover:bg-neutral-300"
        aria-label="Format Underline"
       >
        TODO
       </button> */}
      <button type="button" disabled={disabled} onClick={() => {
      editor.focus();
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
    }} className="flex h-8 w-8 items-center line-through justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" aria-label="Format Strikethrough" data-active={isStrikethrough}>
        S
      </button>
      <Divider data-sentry-element="Divider" data-sentry-source-file="toolbar-plugin.tsx" />
      <button type="button" disabled={disabled} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" onClick={formatBulletList} aria-label="Bullet List">
        <ListBulletIcon className="h-4 w-4" data-sentry-element="ListBulletIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <button type="button" disabled={disabled} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" onClick={formatNumberedList} aria-label="Numbered List">
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="toolbar-plugin.tsx">
          <path d="M7.5 11.25H16.25V12.5H7.5V11.25ZM7.5 2.5H16.25V3.75H7.5V2.5ZM2.5 5V0H1.25V0.625H0V1.875H1.25V5H0V6.25H3.75V5H2.5ZM3.75 15H0V12.5C0 12.1685 0.131696 11.8505 0.366116 11.6161C0.800537 11.3817 0.918479 11.25 1.25 11.25H2.5V10H0V8.75H2.5C2.83152 8.75 3.14946 8.8817 3.38388 9.11612C3.6183 9.35054 3.75 9.66848 3.75 10V11.25C3.75 11.5815 3.6183 11.8995 3.38388 12.1339C3.14946 12.3683 2.83152 12.5 2.5 12.5H1.25V13.75H3.75V15Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="toolbar-plugin.tsx" />
        </svg>
      </button>
      <Divider data-sentry-element="Divider" data-sentry-source-file="toolbar-plugin.tsx" />
      <button type="button" datatype="hyperlink" disabled={disabled} className={clsx("flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 data-[active='true']:bg-royal-blue-40 disabled:text-opacity-60 disabled:hover:bg-transparent", {
      "bg-royal-blue-40": isLink
    })} onClick={insertLink} aria-label="Insert Link">
        <PaperClipIcon className="w-4 h-4" data-sentry-element="PaperClipIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <Divider data-sentry-element="Divider" data-sentry-source-file="toolbar-plugin.tsx" />
      <button type="button" disabled={disabled} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" onClick={() => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
    }} aria-label="Numbered List">
        <Bars3BottomLeftIcon className="w-4 h-4" data-sentry-element="Bars3BottomLeftIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <button type="button" disabled={disabled} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" onClick={() => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
    }} aria-label="Numbered List">
        <Bars3Icon className="w-4 h-4" data-sentry-element="Bars3Icon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <button type="button" disabled={disabled} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" onClick={() => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
    }} aria-label="Numbered List">
        <Bars3BottomRightIcon className="w-4 h-4" data-sentry-element="Bars3BottomRightIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      <Divider data-sentry-element="Divider" data-sentry-source-file="toolbar-plugin.tsx" />
      <button type="button" disabled={disabled} className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-royal-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-royal-blue-40 data-[active='true']:text-neutral-800" onClick={() => {
      editor.focus();
      editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
    }} aria-label="Indent">
        <ArrowRightIcon className="w-4 h-4" data-sentry-element="ArrowRightIcon" data-sentry-source-file="toolbar-plugin.tsx" />
      </button>
      {/* <button
        type="button"
        disabled={disabled}
        className="flex h-8 w-8 items-center justify-center rounded-md text-neutral-800 hover:bg-grey-blue-20 disabled:text-opacity-60 disabled:hover:bg-transparent data-[active='true']:bg-grey-blue-20 data-[active='true']:text-neutral-800"
        onClick={() => {
          editor.focus();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
        }}
        aria-label="Code"
       >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor">
          <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z" />
        </svg>
       </button> */}
    </div>;
}
const Divider = () => <span className="!mx-2 block h-6 w-[1px] bg-neutral-400" data-sentry-component="Divider" data-sentry-source-file="toolbar-plugin.tsx" />;