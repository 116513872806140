import type { InitialEditorStateType } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { LexicalEditor } from "lexical";
import { CLEAR_HISTORY_COMMAND, COMMAND_PRIORITY_EDITOR } from "lexical";
import { useEffect } from "react";
import { RESET_EDITOR_COMMAND } from "../commands";
import useLayoutEffectImpl from "../shared/useLayoutEffect";
type Props = Readonly<{
  isDirty: boolean;
  defaultValue: InitialEditorStateType | undefined;
  currentValue: string | undefined;
}>;
const handleReset = (editor: LexicalEditor, defaultValue: InitialEditorStateType | undefined) => {
  if (typeof defaultValue === "string") {
    const initialEditorState = editor.parseEditorState(defaultValue);
    editor.setEditorState(initialEditorState);

    // REINTIALIZE HISTORY
    editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
  }
};
export function ResetEditorPlugin({
  isDirty,
  defaultValue,
  currentValue
}: Props): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  // ! This listen to changes of value coming from outside of the LexicalEditor. Since RHF canno't reset the value manually because of the way lexical handles the value, we listen for changes, compare it to the default value and reset it if needed.
  useEffect(() => {
    if (currentValue === defaultValue && isDirty) {
      editor.dispatchCommand(RESET_EDITOR_COMMAND, undefined);
    }
  }, [isDirty, currentValue, defaultValue, editor]);
  useLayoutEffectImpl(() => {
    return editor.registerCommand(RESET_EDITOR_COMMAND, () => {
      editor.update(() => {
        handleReset(editor, defaultValue);
      });
      return true;
    }, COMMAND_PRIORITY_EDITOR);
  }, [editor]);
  return null;
}