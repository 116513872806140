import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getFontWeightClassName = (fontWeight: "400" | "500" | "600" | "700") => {
  return {
    "font-normal": fontWeight === "400",
    "font-medium": fontWeight === "500",
    "font-semibold": fontWeight === "600",
    "font-bold": fontWeight === "700",
  };
};
