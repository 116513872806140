import { i18n } from "@lingui/core";
import { RHFLabeledSelect } from "~/components/rhf";
import { trpc } from "~/utils/trpc";
export const RoleDepartmentField: React.FC<{
  fieldEnabled: boolean;
}> = ({
  fieldEnabled
}) => {
  const query = trpc.referential.departments.list.useQuery(undefined, {
    enabled: fieldEnabled
  });
  if (!fieldEnabled) {
    return null;
  }
  if (query.isLoading) {
    return "Loading...";
  }
  if (query.isError) {
    return "Une erreur est survenue";
  }
  const options = query.data.departments.map(({
    id,
    name
  }) => ({
    id,
    label: name
  }));
  return <RHFLabeledSelect label={i18n._({
    id: "common.department",
    message: "Département"
  })} name="roleDepartmentId" options={options} data-sentry-element="RHFLabeledSelect" data-sentry-component="RoleDepartmentField" data-sentry-source-file="roleDepartmentField.tsx" />;
};