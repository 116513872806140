import clsx from "clsx";
import { forwardRef } from "react";
export const Checkbox = forwardRef<HTMLInputElement, React.ComponentPropsWithRef<"input"> & {
  isError?: boolean;
  rounded?: boolean;
}>(function Checkbox({
  isError = false,
  rounded = false,
  disabled = false,
  className,
  ...props
}, forwardedRef) {
  return <input disabled={disabled} type="checkbox" className={clsx("flex h-5 w-5 rounded-[3px] cursor-pointer items-center justify-center border border-aubergine ring-0 ring-offset-0 after:inline-block after:content-[''] after:checked:h-4 after:checked:w-4 after:checked:bg-aubergine  after:indeterminate:h-px after:indeterminate:w-2.5 after:indeterminate:bg-neutral-400 disabled:cursor-default", rounded ? "rounded-full after:rounded-full" : "after:rounded-[2px]", {
    "border-error after:checked:bg-error": isError
  }, {
    "border-aubergine after:checked:bg-aubergine": !isError && !disabled
  }, {
    "after:checked:bg-neutral-500 bg-neutral-200 border-neutral-500": disabled
  }, {
    "bg-transparent": !disabled
  }, className)} {...props} ref={forwardedRef} />;
});