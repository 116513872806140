import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { EMPTY_RICHTEXT } from "../constant";

/**
 * Inspiration comes from this thread https://github.com/facebook/lexical/discussions/1937#discussioncomment-2631544
 * This plugin is used to listen to the initial value change.
 * It is used to set the initial value of the editor.
 * In readOnly, the initial value cannot be changed once the component mounted. The value reveived by the BasicRichTextEditor on first render is used.
 * In our case, the query which provide the initial value can take some time to send the value. We then need to listen on that value change and edit it manually.
 */
export function ListenValueChangePlugin({
  value
}: {
  value?: string;
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (value && value !== EMPTY_RICHTEXT) {
      const initialEditorState = editor.parseEditorState(value);
      editor.setEditorState(initialEditorState);
    }
  }, [value, editor]);
  return null;
}