import { KeyOption, matchSorter } from "match-sorter";

import { MultiOption } from "./types";

export function getOptionsItems<Option extends MultiOption>({
  optionItems,
  inputValue,
  keys = ["id"],
}: {
  inputValue: string;
  optionItems: Option[];
  keys: readonly KeyOption<Option>[];
}) {
  if (!inputValue) {
    return optionItems;
  }

  return matchSorter(optionItems, inputValue, { keys, baseSort: (a, b) => (a.index < b.index ? -1 : 1) });
}
