import type { GetServerSideProps } from "next";
import getConfig from "next/config";
import React from "react";
import { z } from "zod";

// eslint-disable-next-line import/no-cycle
import { AuthFormDev } from "~/components/auth/authFormDev";
import { AuthFormProd } from "~/components/auth/authFormProd";
import { AuthLayout } from "~/components/auth/authLayout";
import { ErrorPage } from "~/components/auth/errorPage";
import { env } from "~/env/server.mjs";
import { getIpRangesWBI } from "~/server/utils/auth";
import { getIp, isIpInRange } from "~/utils/auth/ip";
type AuthPageProps = {
  isProduction: boolean;
  errorCorrelationId?: string;
  postLoginPath: string;
  kcIdpHint?: string;
};
const routeQuerySchema = z.object({
  error_correlation_id: z.string().optional(),
  post_login_path: z.string(),
  kc_idp_hint: z.string().optional()
});
export const getServerSideProps: GetServerSideProps<AuthPageProps> = async context => {
  const {
    publicRuntimeConfig
  } = getConfig();
  const isProduction = publicRuntimeConfig.APP_ENV === "production";
  const {
    error_correlation_id,
    post_login_path,
    kc_idp_hint
  } = routeQuerySchema.parse(context.query);
  if (error_correlation_id) {
    return {
      props: {
        isProduction,
        errorCorrelationId: error_correlation_id,
        postLoginPath: post_login_path
      }
    };
  }
  const userIp = getIp(context.req);
  const ipRangesQuery = await getIpRangesWBI();
  const ipInRange = ipRangesQuery.ip_ranges.some(range => isIpInRange(userIp, range.start_ip, range.end_ip));
  if (ipInRange) {
    return {
      redirect: {
        destination: `${env.NEXT_APP_URL}/api/auth/login?post_login_path=${encodeURIComponent(post_login_path)}`,
        permanent: false
      }
    };
  }
  if (kc_idp_hint) {
    return {
      redirect: {
        destination: `${env.NEXT_APP_URL}/api/auth/login?post_login_path=${encodeURIComponent(post_login_path)}&kc_idp_hint=${kc_idp_hint}`,
        permanent: false
      }
    };
  }
  return {
    props: {
      isProduction,
      postLoginPath: post_login_path,
      kcIdpHint: env.KEYCLOAK_IDP_APPLICANT
    }
  };
};
const AuthPage: React.FC<AuthPageProps> = ({
  isProduction,
  errorCorrelationId,
  postLoginPath,
  kcIdpHint
}) => {
  if (errorCorrelationId) {
    return <ErrorPage errorCorrelationId={errorCorrelationId} />;
  }
  return <AuthLayout data-sentry-element="AuthLayout" data-sentry-component="AuthPage" data-sentry-source-file="auth.tsx">
      {isProduction ? <AuthFormProd kcIdpHint={kcIdpHint} postLoginPath={postLoginPath} /> : <div>
          <AuthFormDev postLoginPath={postLoginPath} />
          <span className="h-px block w-full bg-neutral-400 mt-10" />
          <AuthFormProd kcIdpHint={kcIdpHint} postLoginPath={postLoginPath} />
        </div>}
    </AuthLayout>;
};
export default AuthPage;