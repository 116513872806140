import { Trans } from "@lingui/react";
import { useRouter } from "next/router";
import { useForm, useWatch } from "react-hook-form";
import { RoleDepartmentField } from "~/components/auth/devRoleFields/roleDepartmentField";
import { RoleServiceField } from "~/components/auth/devRoleFields/roleServiceField";
import { RoleSubDepartmentField } from "~/components/auth/devRoleFields/roleSubDepartmentField";
import { RoleTypeField } from "~/components/auth/devRoleFields/roleTypeField";
import { UserField } from "~/components/auth/devRoleFields/userField";
import { Button } from "~/components/button";
import { Form, RHFLabeledShortText } from "~/components/rhf";
import { Typography } from "~/components/typography";
import { i18n } from "~/i18nSetup";
import type { SignInFormData } from "~/types/auth";
import type { Role } from "~/types/database";
import { trpc } from "~/utils/trpc";
import { isRoleTypeDepartmentDependent, isRoleTypeServiceDependent, isRoleTypeSubDepartmentDependent } from "~/utils/user-role";
export const AuthFormDev: React.FC<{
  postLoginPath: string;
}> = ({
  postLoginPath
}) => {
  const router = useRouter();
  const methods = useForm<SignInFormData>({
    defaultValues: {}
  });
  const loginMutation = trpc.auth.testingUsers.login.useMutation({
    onSuccess: () => {
      router.push(postLoginPath);
    }
  });
  const roleType = useWatch({
    name: "roleType",
    control: methods.control
  }) as Role["type"];
  const roleTypeIsDefined = !!roleType;
  const roleDepartmentIsDefined = !!useWatch({
    name: "roleDepartmentId",
    control: methods.control
  });
  const roleSubDepartmentIsDefined = !!useWatch({
    name: "roleSubDepartmentId",
    control: methods.control
  });
  const roleServiceIsDefined = !!useWatch({
    name: "roleServiceId",
    control: methods.control
  });
  const emailIsNotEmpty = !!useWatch({
    name: "email",
    control: methods.control
  });
  const shouldSpecifyDepartment = roleTypeIsDefined && isRoleTypeDepartmentDependent(roleType);
  const shouldSpecifySubDepartment = roleTypeIsDefined && isRoleTypeSubDepartmentDependent(roleType);
  const shouldSpecifyService = roleTypeIsDefined && isRoleTypeServiceDependent(roleType);
  const shouldSpecifyUser = roleTypeIsDefined && (!shouldSpecifyDepartment && !shouldSpecifySubDepartment && !shouldSpecifyService || shouldSpecifyDepartment && roleDepartmentIsDefined || shouldSpecifySubDepartment && roleSubDepartmentIsDefined || shouldSpecifyService && roleServiceIsDefined);
  const isFormValid = emailIsNotEmpty;
  return <Form className="mx-auto flex max-w-sm flex-col items-center justify-center gap-8" methods={methods} onSubmit={formData => {
    if (formData.email) {
      loginMutation.mutate({
        email: formData.email
      });
    }
  }} data-sentry-element="Form" data-sentry-component="AuthFormDev" data-sentry-source-file="authFormDev.tsx">
      <div className="flex flex-col w-full gap-4">
        <Typography.h4 data-sentry-element="unknown" data-sentry-source-file="authFormDev.tsx">
          <Trans id="auth.select_user_based_on_role" message="Sélectionner l'utilisateur sur base de son rôle" data-sentry-element="Trans" data-sentry-source-file="authFormDev.tsx" />
        </Typography.h4>
        <RoleTypeField data-sentry-element="RoleTypeField" data-sentry-source-file="authFormDev.tsx" />
        <RoleDepartmentField fieldEnabled={shouldSpecifyDepartment} data-sentry-element="RoleDepartmentField" data-sentry-source-file="authFormDev.tsx" />
        <RoleSubDepartmentField fieldEnabled={shouldSpecifySubDepartment} data-sentry-element="RoleSubDepartmentField" data-sentry-source-file="authFormDev.tsx" />
        <RoleServiceField fieldEnabled={shouldSpecifyService} data-sentry-element="RoleServiceField" data-sentry-source-file="authFormDev.tsx" />
        <UserField fieldEnabled={shouldSpecifyUser} data-sentry-element="UserField" data-sentry-source-file="authFormDev.tsx" />
      </div>
      <div className="flex flex-col gap-4">
        <Typography.h4 data-sentry-element="unknown" data-sentry-source-file="authFormDev.tsx">
          <Trans id="auth.select_user_based_on_email" message="Sélectionner l'utilisateur sur base de son email" data-sentry-element="Trans" data-sentry-source-file="authFormDev.tsx" />
        </Typography.h4>
        <RHFLabeledShortText label={i18n._({
        id: "common.email",
        message: "Email"
      })} name="email" data-sentry-element="RHFLabeledShortText" data-sentry-source-file="authFormDev.tsx" />
      </div>
      <Button className="w-full" disabled={!isFormValid} data-testid="login-btn" variant="plain" type="submit" data-sentry-element="Button" data-sentry-source-file="authFormDev.tsx">
        <Trans id="common.login" message="Connexion" data-sentry-element="Trans" data-sentry-source-file="authFormDev.tsx" />
      </Button>
    </Form>;
};