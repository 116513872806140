import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ComponentPropsWithoutRef, MouseEventHandler, forwardRef } from "react";
import Typography from "./Typography";
export const Badge = forwardRef(function Badge({
  className,
  children,
  onClose,
  rounded = "rounded-2xl",
  fontWeight = "600",
  ...props
}: ComponentPropsWithoutRef<"div"> & {
  onClose?: MouseEventHandler<HTMLButtonElement>;
  rounded?: string;
  fontWeight?: "600" | "700";
}, ref: React.ForwardedRef<HTMLDivElement>) {
  return <div ref={ref} className={clsx("inline-flex w-full max-w-max items-center px-3 py-1.5", rounded, className ?? "bg-neutral-500")} {...props}>
      <Typography.note className="line-clamp-1" fontWeight={fontWeight}>
        {children}
      </Typography.note>
      {onClose ? <button type="button" onClick={onClose} tabIndex={-1} className="-my-1 -mr-2 ml-1 inline-flex items-center justify-center rounded-full p-1 text-neutral-600 hover:bg-neutral-500 hover:text-neutral-800">
          <XMarkIcon className="h-4 w-4 stroke-[2.5]" />
        </button> : null}
    </div>;
});