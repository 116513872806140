import { Trans } from "@lingui/react";
import { Button } from "@monwbi/ui";
import Typography from "@monwbi/ui/src/Typography";
import { AuthLayout } from "~/components/auth/authLayout";
export const ErrorPage: React.FC<{
  errorCorrelationId: string;
}> = ({
  errorCorrelationId
}) => {
  return <AuthLayout data-sentry-element="AuthLayout" data-sentry-component="ErrorPage" data-sentry-source-file="errorPage.tsx">
      <Typography.h4 data-sentry-element="unknown" data-sentry-source-file="errorPage.tsx">
        <Trans id="auth.login_attempt_failed" message="La tentative de connexion a échoué" data-sentry-element="Trans" data-sentry-source-file="errorPage.tsx" />
      </Typography.h4>
      <Typography.body data-sentry-element="unknown" data-sentry-source-file="errorPage.tsx">
        <Trans id="common.correlation_id" message="ID de corrélation:" data-sentry-element="Trans" data-sentry-source-file="errorPage.tsx" />
      </Typography.body>
      <Typography.error data-sentry-element="unknown" data-sentry-source-file="errorPage.tsx">{errorCorrelationId}</Typography.error>
      <Button variant="plain" href="/auth" className="mt-4" data-sentry-element="Button" data-sentry-source-file="errorPage.tsx">
        <Trans id="common.try_again" message="Réessayer" data-sentry-element="Trans" data-sentry-source-file="errorPage.tsx" />
      </Button>
    </AuthLayout>;
};