import { PropsWithChildren } from "react";
import auraWBI from "../assets/Aura_WBI_haute_resolution.png";
import feelInspired from "../assets/feel_inspired_blanc_haute_resolution.png";
import logoWBI from "../assets/logo_wbi_noir_haute_resolution.png";
export const UnauthenticatedLayout: React.FC<PropsWithChildren> = ({
  children
}) => {
  return <div className="flex flex-col sm:flex-row" data-sentry-component="UnauthenticatedLayout" data-sentry-source-file="index.tsx">
      <div className="flex min-h-screen flex-1 flex-col items-center justify-center gap-40 bg-gradient-to-b from-slate-800 via-slate-800 to-[#342264] text-3xl sm:gap-20">
        <div className="relative max-w-[100px] lg:max-w-[115px] xl:max-w-[130px]">
          <img src={logoWBI as unknown as string} alt="Aura Logo WBI" className="relative h-[unset] w-full object-contain invert" />
        </div>
        <div className="relative max-w-[250px] lg:max-w-[275px] xl:max-w-[300px]">
          <img src={auraWBI as unknown as string} alt="Aura Logo WBI" className="relative h-[unset] w-full object-contain" />
        </div>
        <div className="relative max-w-[150px] lg:max-w-[175px] xl:max-w-[200px]">
          <img src={feelInspired as unknown as string} alt="feel inspired text" className="relative h-[unset] w-full object-contain" />
        </div>
      </div>
      <div className="flex flex-col flex-1 gap-4 justify-center items-center bg-gray-100">{children}</div>
    </div>;
};